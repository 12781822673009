import React, { useContext, createContext } from 'react';
import { UserContext } from './userContext'; // Import your UserContext

export const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
    const { accessToken, refreshTokens } = useContext(UserContext);

    const makeRequest = async ({ url, method = 'GET', data = null, params = {}, headers = {} }) => {
        // console.log(url)
        let fullUrl;
        if(Object.keys(params).length !== 0){
            fullUrl = `${url}?${new URLSearchParams(params).toString()}`;
        }else{
            fullUrl = url
        }

        const options = {
            method,
            headers: {
                ...headers,
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: null,
        };

        if (data) {
            if (data instanceof FormData) {
                options.body = data;
                delete options.headers['Content-Type']; // FormData should not have Content-Type header
            } else {
                options.body = JSON.stringify(data);
            }
        }

        try {
            let response = await fetch(fullUrl.toString(), options);
            if (response.status === 401) {
                await refreshTokens();
                options.headers['Authorization'] = `Bearer ${accessToken}`; // Update the accessToken after refresh
                response = await fetch(fullUrl.toString(), options); // Retry the request
            }
            if(!response.ok){
                throw new Error('Failed to do the required task')
            }

            return await response.json();
        } catch (error) {
            console.error('API request error: ', error);
            throw error;
        }
    };

    const apiClient = {
        get: (url, params = {}, headers = {}) => makeRequest({ url, method: 'GET', params, headers }),
        post: (url, data, params = {}, headers = {}) => makeRequest({ url, method: 'POST', data, params, headers }),
        put: (url, data, params = {}, headers = {}) => makeRequest({ url, method: 'PUT', data, params, headers }),
        delete: (url, params = {}, headers = {}) => makeRequest({ url, method: 'DELETE', params, headers }),
    };

    return (
        <ApiContext.Provider value={apiClient}>
            {children}
        </ApiContext.Provider>
    );
};
