import React, { useState } from "react";
import SendMessageModal from "../../message-modality/message-modality";
import { formatDate } from '../../../utilities/utility'
import {
    Canvas,
    AgentCard,
    AgentName,
    SubscriptText,
    IconButton,
    FlexDivColumn,
    DateText,
    LeasePackageDiv,
    CreateLeaseDiv,
    LeaseCard,
    Heading,
    FlexDiv,
    Th,
    ApplicantName,
    BulletIcon,
    StatusBadge,
    ContactDiv,
    StyledTable,
    TableRow,
    TableCellWrapper,
    DateDiv
} from '../shared-styles'

import { ReactComponent as Message } from '../../../assets/Message.svg'
import { ReactComponent as Download } from '../../../assets/Download.svg'

const LeaseInfo = ({ applicants, guarantors, leaseDoc, leaseDue, agent, setFocusTab, activateModal}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tenantsToSend, setTenantsToSend] = useState([]);

    const handleOpenModalForAll = () => {
        setTenantsToSend(applicants); // Set all tenants
        setIsModalOpen(true);
    };

    const handleOpenModalForOne = (tenant) => {
        setTenantsToSend([tenant]); // Set only one tenant
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <Canvas>
            <FlexDiv>
                <AgentCard>
                    <div>
                        <AgentName>{agent?.name}</AgentName>
                        <SubscriptText>{agent?.brokerage}</SubscriptText>
                    </div>
                    <IconButton onClick={() => setFocusTab('Chat')}>
                        <Message/>
                    </IconButton>
                </AgentCard>
                <LeaseCard leaseDoc = {leaseDoc}>
                    {
                        leaseDue && 
                        <DateDiv>
                            <SubscriptText>Lease Due Date:</SubscriptText>
                            <DateText> {formatDate(new Date(leaseDue)).formattedDate}</DateText>
                        </DateDiv>   
                    }
                    {
                        leaseDoc ?
                        <LeasePackageDiv onClick={activateModal}>Lease Agreement <Download/></LeasePackageDiv>
                        :
                        <CreateLeaseDiv onClick={activateModal}><span>+</span><span>Create Lease</span></CreateLeaseDiv>
                    }
                </LeaseCard>
            </FlexDiv>
            <FlexDivColumn>
                <Heading>Lease & Documents Status</Heading>
                <StyledTable>
                    <thead style={{borderBottom:'1px solid #f1f1f1'}}>
                        <tr>
                            <Th><TableCellWrapper>Name</TableCellWrapper></Th>
                            <Th><TableCellWrapper>Lease Status</TableCellWrapper></Th>
                            {/* <Th><TableCellWrapper>W8/W9 Status</TableCellWrapper></Th> */}
                            <Th><TableCellWrapper><ContactDiv onClick={handleOpenModalForAll}>Email All</ContactDiv></TableCellWrapper></Th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        applicants?.map(applicant => 
                            <TableRow>
                                <ApplicantName><TableCellWrapper>{applicant.name}</TableCellWrapper></ApplicantName>
                                <td>
                                    <TableCellWrapper>
                                        <StatusBadge status = {applicant.leaseStatus}>
                                            <BulletIcon/>
                                            {applicant.leaseStatus}
                                        </StatusBadge>
                                    </TableCellWrapper>
                                </td>
                                {/* <td>
                                    <TableCellWrapper>
                                        <StatusBadge status = {applicant.w9Status}>
                                            <BulletIcon/>
                                            {applicant.w9Status}
                                        </StatusBadge>
                                    </TableCellWrapper>
                                </td> */}
                                <td>
                                    <TableCellWrapper>
                                        <IconButton onClick={() => handleOpenModalForOne(applicant)}>
                                            <Message/>
                                        </IconButton>
                                    </TableCellWrapper>
                                </td>
                            </TableRow>
                        )
                    }
                    {
                        guarantors?.map(guarantor => 
                            <TableRow>
                                <ApplicantName><TableCellWrapper>{guarantor.name}</TableCellWrapper></ApplicantName>
                                <td>
                                    <TableCellWrapper>
                                        <StatusBadge status = {guarantor.leaseStatus}>
                                            <BulletIcon/>
                                            {guarantor.leaseStatus}
                                        </StatusBadge>
                                    </TableCellWrapper>
                                </td>
                                {/* <td>
                                    <TableCellWrapper>
                                        <StatusBadge status = {applicant.w9Status}>
                                            <BulletIcon/>
                                            {applicant.w9Status}
                                        </StatusBadge>
                                    </TableCellWrapper>
                                </td> */}
                                <td>
                                    <TableCellWrapper>
                                        <IconButton onClick={() => handleOpenModalForOne(guarantor)}>
                                            <Message/>
                                        </IconButton>
                                    </TableCellWrapper>
                                </td>
                            </TableRow>
                        )
                    }
                    </tbody>
                </StyledTable>
            </FlexDivColumn>
            <SendMessageModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                tenants={tenantsToSend}
                tenantsList={applicants}
                setTenantsToSend={setTenantsToSend}
            />
        </Canvas>
    )
}

export default LeaseInfo