import React, { useMemo, useState } from "react";
import ChatChannel from "../agent-chat/agent-chat";
import DocumentStatusList from "../document-status-list/document-status-list";
import ApplicationSummary from "../application-summary/application-summary";
import EditApplicantsModal from "../edit-application-modal/edit-application-modal"; // Import the modal component
import UploadSection from "../upload-file/upload-file";
import { toast } from "react-toastify";
// import { useLocation } from "react-router-dom";
import { formatDate } from "../../utilities/utility";
import { ReactComponent as Download } from '../../assets/Download.svg'
import {
    Canvas, Title, StatusBadge, BulletIcon, Tab, TabContentHeader, TabHolder, SubmittedDate,
    BorderContainer, Heading, CHatContainer, Disclaimer, ChatHeader, TitleAndBadgeContainer,
    DocumentsContainer, HeadingContainer, NextButton, StyledTable, TableCellWrapper, TableRow,
    Th, ApplicantName, LeaseStatusBadge, LeaseDownload, AddButton
} from './agent-application-status.styles'


const AgentApplicationStatus = ({applicationData}) => {

    const [focusTab, setFocusTab] = useState('Summary')
    const [showButton, setShowButton] = useState(true);
    const [doc, setDoc] = useState(applicationData?.agent?.w9 ? [{
        value: applicationData?.agent?.w9,
        documentType: 'w9'
    }] : []);
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
    const [editType, setEditType] = useState('applicants'); // State to track if editing applicants or guarantors
    const [applicants, setApplicants] = useState(applicationData.applicants);
    const [guarantors, setGuarantors] = useState(applicationData.applicants.filter(applicant => applicant.hasCosigner).map(applicant => applicant.cosigner));

    const toggleModal = (type) => {
        setEditType(type);
        setIsModalOpen(!isModalOpen);
    };

    const updateApplicants = async (updatedData) => {
        if (editType === 'applicants') {
            setApplicants(updatedData.map(updated => ({
                ...applicants.find(app => app.id === updated.id),
                email: updated.email
            })));
        } else {
            setGuarantors(updatedData.map(updated => ({
                ...guarantors.find(guar => guar.guarantorId === updated.guarantorId),
                email: updated.email
            })));
        }
    };

    const applicantApplicationDocuments = useMemo(() => {
        const docsByApplicant = applicants.map((applicant, index) => {
            const docs = ['Application Form',...applicant.requiredDocs].reduce((acc, doc) => {
                // Find the submitted document that matches the required doc
                const submittedDoc = applicant.documents.find(submitted => submitted.documentType === doc);
                // Assign the document value if submitted, or null if not found
                acc[doc] = submittedDoc ? submittedDoc.value : null;
                return acc;
            }, {});
            // Return an object keyed by applicant name or a unique identifier with their docs
            return { [applicant.name]: { documents: docs, appLink: applicant.applicationFormLink} };
        });

        // Convert the array of applicants into a single object
        return docsByApplicant.reduce((acc, current) => ({ ...acc, ...current }), {});
    }, [applicants])

    const guarantorApplicationDocuments = useMemo(() => {
        const docsByGuarantor = applicants.map((applicant, index) => {
            if (applicant.hasCosigner){
                const docs = ['Guarantor Form',...applicant.cosigner.requiredDocs].reduce((acc, doc) => {
                    // Find the submitted document that matches the required doc
                    const submittedDoc = applicant.cosigner.documents.find(submitted => submitted.documentType === doc);
                    // Assign the document value if submitted, or null if not found
                    acc[doc] = submittedDoc ? submittedDoc.value : null;
                    return acc;
                }, {});
                // Return an object keyed by applicant name or a unique identifier with their docs
                return { [applicant.cosigner.name]: { documents: docs, appLink: applicant.cosigner.guarantorFormLink} };
            }
        });

        return docsByGuarantor.reduce((acc, current) => ({ ...acc, ...current }), {});
    }, [applicants])

    const submitw9 = async () => {
        try {
            const formData = new FormData();
            // console.log(applicationData)
            formData.append('dealId', applicationData.dealId);
            formData.append('w9', doc[0].value);
            const response = await fetch(`/api/deal/submitw9/`, {
                method: 'PUT',
                body: formData
            })
            if(!response.ok){
                throw new Error('Could not upload w9');
            }
            setShowButton(false);
            toast.success('w9 uploaded successfully!')
        }catch (error) {
            // Handle network errors or other fetch issues
            toast.error("Something went wrong. Reach out to us at support@avaasa.us for assistance");
        }
    }

    const triggerDownload = () => {
        const fileURL = window.location.origin + '/media/'+  applicationData.lease.leaseDoc;
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'download.pdf'); // Specify the file name and extension
        document.body.appendChild(fileLink);

        fileLink.click();

        window.URL.revokeObjectURL(fileURL); // Clean up: revoke the blob URL
        fileLink.remove(); // Remove the temporary link element
    }


    const submittedDate  = formatDate(new Date(applicationData?.submittedTimeStamp)).formattedDate;
    const submittedTime = formatDate(new Date(applicationData?.submittedTimeStamp)).formattedTime;

    const status = applicationData.status === "Pending" ? 'Incomplete' : applicationData.status === "New" ? 'Submitted': applicationData.status 
    // console.log(applicationDocuments)
    return (
        <Canvas>
            <TitleAndBadgeContainer>
                <Title>Application Status</Title>
                <StatusBadge status={status}>
                    <BulletIcon/>
                    {status}
                </StatusBadge>
            </TitleAndBadgeContainer>
            <TabContentHeader>    
                <TabHolder>
                    <Tab onClick={() => setFocusTab('Summary')} active={focusTab=== "Summary"}>Summary</Tab>
                    <Tab onClick={() => setFocusTab('Documents')} active={focusTab === 'Documents'}>Documents</Tab>
                    <Tab onClick={() => setFocusTab('Chat')} active={focusTab === 'Chat'}>Chat</Tab>
                    {
                        applicationData.status === "Approved" && applicationData?.lease?.leaseDoc && 
                        <Tab onClick={() => setFocusTab('Lease')} active={focusTab === 'Lease'}>Lease</Tab>
                    }
                    {
                        applicationData?.w9 === "" && applicationData.id !== "" && applicationData.status === "Approved" &&
                        <Tab onClick={() => setFocusTab('Upload W9')} active={focusTab === 'Upload W9'}>Upload W9</Tab>
                    }
                </TabHolder>
                <SubmittedDate>Submitted On: {submittedDate} {submittedTime}</SubmittedDate>
            </TabContentHeader>
            {
                focusTab === "Summary" &&
                <BorderContainer>
                    <ApplicationSummary
                        property={applicationData.property}
                        applicationData={applicationData}
                        offerMoveinDeposits = {applicationData.offerMoveinDeposits}
                    />
                </BorderContainer>
            }
            {
                focusTab === "Documents" &&
                <>
                    <BorderContainer>
                        <HeadingContainer>
                            <div style={{display:'flex', gap: '10px', alignItems: 'center'}}>
                                <Heading>Applicants</Heading>
                                <AddButton onClick={() => toggleModal('applicants')}>Edit Applicants</AddButton>
                            </div>
                            <DocumentsContainer>
                            {
                                Object.entries(applicantApplicationDocuments).map(([applicantKey, value]) => 
                                    <DocumentStatusList
                                        applicantKey = {applicantKey}
                                        documents = {value.documents}
                                        appLink = {window.location.origin + value.appLink}
                                    />
                                )
                            }
                            </DocumentsContainer>
                        </HeadingContainer>
                        {
                            Object.keys(guarantorApplicationDocuments).length > 0 && ( // Assuming this is meant to check if the data is available
                                <HeadingContainer>
                                    <div style={{display:'flex', gap: '10px', alignItems: 'center'}}>
                                        <Heading>Guarantors</Heading>
                                        <AddButton onClick={() => toggleModal('guarantors')}>Edit Guarantors</AddButton>
                                    </div>
                                    <DocumentsContainer>
                                        {
                                            Object.entries(guarantorApplicationDocuments).map(([applicantKey, value]) => 
                                                <DocumentStatusList
                                                    applicantKey = {applicantKey}
                                                    documents = {value.documents}
                                                    appLink = {window.location.origin + value.appLink}
                                                />
                                            )
                                        }
                                    </DocumentsContainer>
                                </HeadingContainer>
                            )
                        }
                    </BorderContainer>
                    <Disclaimer>*The application package is auto sent to the management once all documents are submitted.</Disclaimer>
                </>
            }
            {
                focusTab === 'Chat'  && applicationData.status === "Pending"?
                <CHatContainer>
                    <div 
                        style ={{
                            display:'flex', textAlign:'center', 
                            alignItems:'center', justifyContent:'center', 
                            fontSize:'20px', color: '#747474', 
                            height:'100%',padding:'30px'
                        }}
                    >
                        Chat enabled when application is submitted by all applicants.
                    </div>
                </CHatContainer>
                :
                focusTab === 'Chat'  && applicationData.status !== "Pending" &&
                <CHatContainer>
                    <ChatHeader>Chat</ChatHeader>
                    <ChatChannel
                        agentId={applicationData.agent.id}
                        groupId={applicationData.externalGroupId}
                    />
                </CHatContainer>
            }
            {
                focusTab === "Lease" &&
                <BorderContainer>
                    {
                        applicationData?.lease?.leaseDoc && 
                        <div style={{justifyContent:'space-between', display: 'flex'}}>
                        <Heading>Lease Document</Heading>
                        <LeaseDownload onClick={triggerDownload}>Download <Download/></LeaseDownload>
                        </div>
                    }
                    <div style={{justifyContent:'space-between', display: 'flex'}}>
                        <Heading>Landlord</Heading>
                        <LeaseStatusBadge status = {applicationData?.lease?.landlordSignatureStatus}>
                            <BulletIcon/>
                            {applicationData?.lease?.landlordSignatureStatus}
                        </LeaseStatusBadge>
                    </div>
                    <HeadingContainer>
                        <Heading>Applicants</Heading>
                        <StyledTable>
                            <thead style={{borderBottom:'1px solid #f1f1f1'}}>
                                <TableRow>
                                    <Th><TableCellWrapper>Name</TableCellWrapper></Th>
                                    <Th><TableCellWrapper>Lease Status</TableCellWrapper></Th>
                                </TableRow>
                            </thead>
                            <tbody>
                            {
                                applicationData?.lease?.applicantLeaseStatus?.map(applicant => 
                                    <TableRow>
                                        <ApplicantName><TableCellWrapper>{applicant.name}</TableCellWrapper></ApplicantName>
                                        <td style = {{width:'fit-content'}}>
                                            <TableCellWrapper>
                                                <LeaseStatusBadge status = {applicant.leaseStatus}>
                                                    <BulletIcon/>
                                                    {applicant.leaseStatus}
                                                </LeaseStatusBadge>
                                            </TableCellWrapper>
                                        </td>
                                    </TableRow>
                                )
                            }
                            </tbody>
                        </StyledTable>
                    </HeadingContainer>    
                    {
                        applicationData?.lease?.guarantorLeaseStatus.length > 0 &&
                        <HeadingContainer>         
                            <Heading>Guarantors</Heading>
                            <StyledTable>
                                <thead style={{borderBottom:'1px solid #f1f1f1'}}>
                                    <TableRow>
                                        <Th><TableCellWrapper>Name</TableCellWrapper></Th>
                                        <Th><TableCellWrapper>Lease Status</TableCellWrapper></Th>
                                    </TableRow>
                                </thead>
                                <tbody>
                                {
                                    applicationData?.lease?.guarantorLeaseStatus?.map(guarantor => 
                                        <TableRow>
                                            <ApplicantName><TableCellWrapper>{guarantor.name}</TableCellWrapper></ApplicantName>
                                            <td style= {{width:'fit-content'}}>
                                                <TableCellWrapper>
                                                    <LeaseStatusBadge status = {guarantor.leaseStatus}>
                                                        <BulletIcon/>
                                                        {guarantor.leaseStatus}
                                                    </LeaseStatusBadge>
                                                </TableCellWrapper>
                                            </td>
                                        </TableRow>
                                    )
                                }
                                </tbody>
                            </StyledTable>
                        </HeadingContainer>
                    }    
                </BorderContainer>
            }
            {
                focusTab === "Upload W9" &&
                <HeadingContainer style ={{padding: '0px 20px'}}>
                    <div>
                        <Heading>Upload your W9<span style={{color: 'red'}}>*</span></Heading>
                    </div>
                    <UploadSection
                        setDocs = {setDoc}
                        doc = {doc[0] || 'w9'}
                        initialFile={doc[0]} // Find the document that matches the required doc
                    />
                    {
                        showButton && <NextButton onClick={submitw9}>Submit w9</NextButton>
                    }
                </HeadingContainer>
            }
            <EditApplicantsModal
                isOpen={isModalOpen}
                toggle={toggleModal}
                type = {editType}
                applicants={editType === 'applicants' ? applicants : guarantors}
                updateApplicants={updateApplicants}
                applicationId = {applicationData.id}
            />
        </Canvas>
    )
}

export default AgentApplicationStatus